import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-1.jpg"
                    alt="About WD IT Supply"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Over WD IT Supply</h2>
                    <p className="description">
                      WD IT Supply, opgericht in november 2023, is specialiseert
                      in het creëren van op maat gemaakte websites, webshops en
                      logo's. Gedreven door passie en expertise in webontwerp en
                      grafisch design, biedt WD IT Supply persoonlijke aandacht
                      en toegewijde service aan elke klant. De focus ligt op het
                      leveren van gebruiksvriendelijke, visueel aantrekkelijke
                      en functionele online oplossingen die de unieke identiteit
                      en doelen van elke klant weerspiegelen. Met een oog voor
                      detail en een streven naar perfectie, staat WD IT Supply
                      garant voor kwaliteit in elk project, groot of klein.
                      <br />
                      <br />
                      Heb je een project in gedachten of wil je meer te weten
                      komen over wat ik kan bieden? Aarzel dan niet om
                      <a href="/contact"> contact</a> met mij op te nemen. Ik
                      sta klaar om jouw ideeën tot leven te brengen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
