import React, { Component } from "react";

const ServiceList = [
  {
    icon: "04",
    title: "Website ontwikkeling",
    description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered.",
  },
  {
    icon: "05",
    title: "Webshop ontwikkeling",
    description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered.",
  },
  {
    icon: "06",
    title: "Logo ontwerp & Branding",
    description:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered.",
  },
];

class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">
                  <img
                    src={`/assets/images/icons/icon-${val.icon}.png`}
                    alt="WD IT Supply"
                  />
                </div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceOne;
