// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import Home from "./home/MainDemo";

// Element Layout
import About from "./elements/About";
import Contact from "./elements/Contact";
import error404 from "./elements/error404";

// Element Layout
import ServiceDetails1 from "./elements/ServiceDetails1";
import ServiceDetails2 from "./elements/ServiceDetails2";
import ServiceDetails3 from "./elements/ServiceDetails3";

// import PortfolioDetails from "./elements/PortfolioDetails";

// Blocks Layout
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
            {/* Element Layot */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              component={About}
            />

            {/* Element Layot */}

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/website-ontwerp`}
              component={ServiceDetails1}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/webshop-ontwerp`}
              component={ServiceDetails2}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/logo-ontwerp`}
              component={ServiceDetails3}
            />

{/*             <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio-details`}
              component={PortfolioDetails}
            /> */}

            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
