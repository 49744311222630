import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://joy-fashion.be/"
            >
              <img
                src="/assets/images/brand/brand-07.png"
                alt="Logo joy-fashion"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.wditsupply.be/"
            >
              <img
                src="/assets/images/brand/brand-08.png"
                alt="Logo WD IT Supply"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.accuraatdakwerken.be/"
            >
              <img
                src="/assets/images/brand/brand-09.png"
                alt="Logo Accuraat Dakwerken"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.cindyvandekerkhof.be/"
            >
              <img
                src="/assets/images/brand/brand-10.png"
                alt="Logo Cindy Van de Kerkhof"
              />
            </a>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
