import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import { Link } from "react-router-dom";
//import { joyfashion } from "../../../../public/assets/images/portfolio/joyfashion.png";

const PortfolioList = [
  {
    image: "image-1",
    category: "Webshop",
    title: "Joy-fashion",
    link: "https://joy-fashion.be/",
  },
  {
    image: "image-2",
    category: "Website",
    title: "Cindy Van de Kerkhof",
    link: "https://www.cindyvandekerkhof.be/",
  },
  {
    image: "image-3",
    category: "Website",
    title: "Accuraat Dakwerken",
    link: "https://www.accuraatdakwerken.be/",
  },
];

class Portfolio extends Component {
  render() {
    let title = "Realisaties",
      description = "Hieronder vindt u enkele realisaties.";
    return (
      <React.Fragment>
        <div className="portfolio-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h2>{title}</h2>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio-slick-activation mt--70 mt_sm--40">
            <Slider {...portfolioSlick2}>
              {PortfolioList.map((value, index) => (
                <div className="portfolio" key={index}>
                  <div className="thumbnail-inner">
                    <div className={`thumbnail ${value.image}`}></div>
                    <div className={`bg-blr-image ${value.image}`}></div>
                  </div>
                  <div className="content">
                    <div className="inner">
                      <p>{value.category}</p>
                      <h4 className="title">
                        <a
                          href={value.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {value.title}
                        </a>
                      </h4>
                      <div className="portfolio-button">
                        <a
                          className="rn-btn"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={value.link}
                        >
                          Naar de Website
                        </a>
                      </div>
                    </div>
                  </div>
                  <Link className="link-overlay" href={value.link}></Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Portfolio;
