import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.props.pageTitle} || WD IT Supply</title>
          <meta
            name="description"
            content="WD IT Supply, gevestigd in Beringen, is gespecialiseerd in het creëren van professionele websites. Wij bieden op maat gemaakte webdesigns en innovatieve digitale oplossingen om uw online aanwezigheid te versterken. Vertrouw op WD IT Supply voor een gebruiksvriendelijke en visueel aantrekkelijke website die uw bedrijf naar een hoger niveau tilt."
          />
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
